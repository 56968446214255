<template>
  <div class="checkWrap" ref="doms">
    <!-- 右侧页面 -->
    <!-- v-if="hasstu" -->

    <div class="check_right" v-if="(stuList && stuList.length !== 0)">
      <div class="userInfo">
        <div class="user flex-align">
          <div class="avatar">
            <img :src="imgurl + currentUser.userAvatar" :onerror="imgUrl" alt />
          </div>
          <div class="info">
            <div class="info_data flex-align">
              <div>{{ currentUser.userName }}</div>
              <el-rate disabled v-model="userStar" v-if="userStar"></el-rate>
              <span v-if="currentCheckInfo.userStar">{{ currentCheckInfo.userStar }}.0分</span>
            </div>

            <div class="time">提交于{{ currentCheckInfo.createTime }}</div>
          </div>
        </div>
        <div v-if="this.themetype!=4">
          <div class="score" v-if="this.workAttr.score == 'true'">
            <div class="score_item">
              <div class="score_num">{{ currentCheckInfo.totalPoints.toFixed(1)  }}</div>
              <!-- <div class="score_num">{{ test }}分数</div> -->
              <div class="score_name">总得分</div>
            </div>
            <div class="score_item">
              <div class="score_num">{{ currentCheckInfo.objectiveScore }}</div>
              <div class="score_name">客观题得分</div>
            </div>
            <div class="score_item">
              <div class="score_num">
                {{ currentCheckInfo.objectiveAccuracy }}<span>%</span>
              </div>
              <div class="score_name">客观题正确率</div>
            </div>
            <div class="score_item">
              <div class="score_num">
                {{ currentCheckInfo.subjectiveScore.toFixed(1) }}
              </div>
              <div class="score_name">主观题得分</div>
            </div>
            <div class="score_item">
              <!-- <div class="score_num">10<span>分</span>56<span>秒</span></div> -->
              <div class="score_num">{{ currentCheckInfo.answerDuration }}</div>
              <div class="score_name">答题时长</div>
            </div>
          </div>
          <div class="score" v-if="this.workAttr.score == 'false'">
            <div class="score_item">
              <div class="score_num">{{ fineScoreType[currentCheckInfos.fineScore] }}</div>
              <div class="score_name">作业成绩</div>
            </div>
            <div class="score_item">
              <div class="score_num">{{ currentCheckInfos.answerDuration }}</div>
              <div class="score_name">答题时长</div>
            </div>
          </div>
        </div>

        <div class="score" v-if="this.themetype===4">
          <div class="score_item">
            <div class="score_num">
              {{speakscore?(speakscore.monosyllabic+speakscore.polysyllabic+speakscore.paragraph+currentCheckInfo.subjectiveScore).toFixed(2):0}}
            </div>
            <!-- <div class="score_num">{{ test }}分数</div> -->
            <div class="score_name">总得分</div>
          </div>
          <div class="score_item">
            <div class="score_num">{{speakscore?speakscore.monosyllabic:0}}</div>
            <div class="score_name">单音节得分</div>
          </div>
          <div class="score_item">
            <div class="score_num">
              {{speakscore?speakscore.polysyllabic:0}}
            </div>
            <div class="score_name">多音节得分</div>
          </div>
          <div class="score_item">
            <div class="score_num">
              {{speakscore?speakscore.paragraph:0}}
            </div>
            <div class="score_name">短文朗读得分</div>
          </div>
          <div class="score_item">
            <div class="score_num">
              {{currentCheckInfo.subjectiveScore}}
            </div>
            <div class="score_name">命题说话得分</div>
          </div>
          <div class="score_item">
            <!-- <div class="score_num">10<span>分</span>56<span>秒</span></div> -->
            <div class="score_num">{{ answertime?answertime:0 }}</div>
            <div class="score_name">答题时长</div>
          </div>
        </div>
        <!-- 1111 -->
        <div class="block">
          <template v-if="workAttr.score == 'false'">
            <el-carousel v-if="FineComentArr&&FineComentArr.length>0" indicator-position="none" :autoplay="false" height="130px">
            <el-carousel-item v-for="(item,index) in FineComentArr" :key="index">
              <div class="user-carousel">
                <div class="item-left flex-align">
                  <!-- <img v-if="item.correctingUserUrl" :src="item.correctingUserUrl" alt /> -->
                  <img src="@/assets/images/home/avatar.png" alt />
                  <div class="name">{{item.correctingUserName}}</div>
                  <div class="time">{{item.updateCorrectingTime}}</div>
                  <div class="bianji" v-if="item.correctingUserId == userinfoID" @click="FineComent(item,index)">编辑
                  </div>
                  <!--  -->
                </div>
                <div class="item-right">{{item.correctingFineComment}}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
          </template>
          <template v-else>
            <el-carousel v-if="FineComentArr" indicator-position="none" :autoplay="false" height="130px">
            <el-carousel-item>
              <div class="user-carousel">
                <div class="item-left flex-align">
                  <img src="@/assets/images/home/avatar.png" alt />
                  <div class="name">{{FineComentArr.correctingUserName}}</div>
                  <div class="time">{{FineComentArr.updateCorrectingTime}}</div>
                  <div class="bianji" v-if="FineComentArr.correctingUserId == userinfoID"></div>
                  <!--  -->
                </div>
                <div class="item-right">{{FineComentArr.correctingFineComment}}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
          </template>
        </div>
      </div>
      <div class="container" v-if="hassubjective">
        <!-- 中部生成的表单 -->
        <div class="center-board">
          <!-- 生成的表单 -->
          <el-scrollbar class="center-scrollbar" v-if="this.themetype!=4">
            <el-row class="center-board-row" :gutter="formConf.gutter">
              <el-form :size="formConf.size" :label-position="formConf.labelPosition" :disabled="formConf.disabled"
                :label-width="formConf.labelWidth + 'px'">
                <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup"
                  :options="dragOptions">
                  <!-- 主观题 -->
                  <template v-if="workAttr.score == 'false'">
                    <draggable-item2 v-for="(item, index) in drawingList" :key="item.renderKey"
                      :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                      :form-conf="formConf" @activeItem="activeFormItem" />
                  </template>
                  <template v-else>
                    <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey"
                      :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                      :form-conf="formConf" @activeItem="activeFormItem" :class="{'actived':currentquestid==item.id}" />
                  </template>


                </draggable>
                <div v-show="!drawingList.length && !fujianList.length" class="empty-info">
                  暂无问卷信息
                </div>
              </el-form>
            </el-row>
          </el-scrollbar>
          <div class="speakcontent" v-else>
            <div>命题说话</div>
            <div class="flex-align-around record">
              <img src="@/assets/pause.png" v-if="!isplay" @click="playaudio">
              <img src="@/assets/play.png" v-if="isplay" @click="pauseaudio">
              <div class="block">
                <el-slider v-model="value1" @change="changeval" :max="this.$refs.audios.duration">
                </el-slider>
              </div>
              <span>{{audiotime}}</span>
            </div>
          </div>
          <!-- 附件题 -->
          <div class="fujian" :class="{'actived':currentquestid==item.id}" v-for="(item, index) in fujianList"
            :key="'q' + index" @click="choosequest(item.id)">
            <div style="margin-bottom:10px">
              {{ item.title }}
            </div>
            <div class="fjexplin" v-if="item.explain">
              {{item.explain}}
            </div>
            <!-- 文件 -->
            <div v-for="(items, index) in item.filelist" :key="'w' + index">
              <!-- <div class="file">
                        <i class="el-icon-tickets"></i>
                        <a :href="imgurl + items.id" target="_blank" style="margin-left:10px">{{
                          items.name
                        }}</a>
                      </div> -->
              <FileVideo :file="items.id+index" :id="items.id" :items="items"></FileVideo>
            </div>

            <!-- 图片 -->
            <el-image v-for="(items, index) in item.imglist" :key="'e' + index"
              style="width: 200px;height:150px;margin-right:20px;border-radius:5px" :src="imgurl + items.id"
              :preview-src-list="item.srclist">
            </el-image>
            <!-- 视频 -->
            <div class="fujian_item" v-for="(items, index) in item.videolist" :key="'r' + index">
              <VideoPlay :file="items.id+index" :id="items.id"></VideoPlay>
            </div>
            <!-- <video class="fujian_item" v-for="(items, index) in item.videolist" :key="'r' + index"
                      :src="imgurl + items.id" controls="controls" width="200x" height="150" style="display:block">
                      您的浏览器不支持播放该视频！
                    </video> -->
            <!-- 音频 -->
            <audio class="fujian_item" style="display:block;margin-top:10px" v-for="(items, index) in item.audiolist"
              :key="index" controls>
              <source :src="imgurl + items.id" type="audio/mpeg" />
              如果该元素不被浏览器支持，则本段文本被显示。
            </audio>
          </div>
        </div>
        <!-- 页面右侧组件 -->
        <right-panel ref="check_right" :arrlength="FineComentArr&&FineComentArr.length" :FineComentArr="FineComentArr"
          :parameter="parameter" :scoreflag="workAttr.score" :id="id" :formid="themetype==4?speakid:formid"
          :power="power" :themetype="themetype" :firstScore="firstScore" :stuInfo="currentUser" :workid="workid"
          @updateCheckInfo="updateCheckInfo" @updateParameter="updateParameter" :currentquestScore="currentquestScore"
          @computeScore="computeScore" />
      </div>
      <div class="empty_sub" v-if="!hassubjective">
        <div class="flex-column-center">
          <img src="@/assets/images/nothing.png" />
          <p style=" margin-top: -55px;font-size: 16px;color: #999;">暂无内容</p>
        </div>
      </div>
    </div>
    <div class="empty-wrapper" style="width:100%" v-if="stuList && stuList.length === 0">
      <Empty :show="stuList && stuList.length === 0" :text="'无需要批改的学员'"></Empty>
    </div>
    <!-- 左侧 -->
    <div class="check_left" v-if="hasstu">
      <div class="student_title">
        <div class="allNum flex-align-between">
          <span>已交学员
            {{hideUsertype[$route.query.projectId]&&hideUsertype[$route.query.projectId].indexOf(userId) >= 0?allSize:allNum }}</span>
          <span>
            <el-input v-model="filterStus" style="width:140px;" suffix-icon="el-icon-search" placeholder="搜索"
              size="mini" @change="filterStu()">
            </el-input>
          </span>
        </div>
        <ul v-if="this.workAttr.score == 'false'" class="complete-kind flex-align">
          <li class="kind-item" :class="{'active-item':activeKind == item.id}"
            v-for="(item,index) in [{name:'全部',id:null},{name:'待批改',id:0},{name:'已批合格',id:1},{name:'已批未合格',id:3}]"
            :key="index" @click="changeCompleteKind(item)">
            {{item.name}}
          </li>
        </ul>
        <ul v-if="this.workAttr.score == 'true'" class="complete-kind flex-align">
          <li class="kind-item" :class="{'active-item':activeKind == item.id}"
            v-for="(item,index) in [{name:'全部',id:null},{name:'待批改',id:0},{name:'已批改',id:1}]" :key="index"
            @click="changeCompleteKind(item)">
            {{item.name}}
          </li>
        </ul>
      </div>
      <!-- 学员列表 -->
      <div class="student_wrap" v-if="stuList && stuList.length !== 0">
        <div class="student_item flex-align-between" :class="{'currentStu':currentUser.userId == item.userId}"
          v-for="(item, index) in stuList" :key="'i' + index" @click="changeUser(item.userId)">
          <div class="userInfo">
            <span class="num" v-html="currentPage>1?(pagesize*(currentPage-1))+index+1:index+1"></span>
            <div class="avatar">
              <img :src="imgurl + item.userAvatar" :onerror="imgUrl" alt />
            </div>
            <span style="max-width:85px">{{ item.userName }}</span>
          </div>
          <div class="item_status">
            <span v-if="item.correctingStatus==0" style="color:#2373ec">待批</span>
            <span v-if="item.correctingStatus==1 ||item.correctingStatus==3">已批</span>
            <span v-if="item.correctingStatus==2">无需批</span>
          </div>
        </div>
      </div>

      <!-- 学员列表分页 -->
      <div class="pagination" v-if="stuList && stuList.length !== 0">
        <el-pagination layout="prev,pager,next,jumper" :total="allSize" :pager-count="5" :page-size="pagesize"
          @size-change="handleSizeChange" :current-page.sync="currentPage" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <Empty :show="stuList && stuList.length === 0" :text="'无需要批改的学员'"></Empty>
    </div>
    <!-- 无提交页面 -->
    <!-- <div v-if="!hasstu" class="empty flex-column-center">
      <img src="@/assets/images/nothing.png" />
      <p>暂无内容</p>
    </div> -->
    <audio ref="audios" @canplay="audioready" @timeupdate="audiotimeupdate" @ended="audioebd"></audio>

  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    debounce
  } from "throttle-debounce";
  import {
    saveAs
  } from "file-saver";
  import ClipboardJS from "clipboard";
  import render from "@/components/render/render";
  import JsonDrawer from "./JsonDrawer";
  import RightPanel from "./check_right_copy";
  import {
    inputComponents,
    selectComponents,
    layoutComponents,
    formConf,
  } from "@/components/generator/config_check";
  import {
    exportDefault,
    beautifierConf,
    isNumberStr,
    titleCase,
    deepClone,
  } from "@/utils/index";
  import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle,
  } from "@/components/generator/html";
  import {
    makeUpJs
  } from "@/components/generator/js";
  import {
    makeUpCss
  } from "@/components/generator/css";
  import drawingDefalut from "@/components/generator/drawingDefalut";
  import logo from "@/assets/logo.png";
  import CodeTypeDialog from "./CodeTypeDialog";
  import DraggableItem from "./DraggableItem_workMan";
  import DraggableItem2 from "./DraggableItem_workMan2";
  import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf,
  } from "@/utils/db";
  import loadBeautifier from "@/utils/loadBeautifier";

  let beautifier;
  const emptyActiveData = {
    style: {},
    autosize: {}
  };
  let oldActiveId;
  let tempActiveData;
  const drawingListInDB = getDrawingList();
  const formConfInDB = getFormConf();
  const idGlobal = getIdGlobal();

  export default {
    props: {
      workid: Number,
      themetype: Number
    },
    components: {
      draggable,
      render,
      JsonDrawer,
      RightPanel,
      CodeTypeDialog,
      DraggableItem,
      DraggableItem2
    },
    data() {
      return {
        hideUsertype: {
          //   238: [14549, 14550, 14551, 14552, 14553, 14554, 14555, 14556, 14557],
          //   248: [8855, 8856, 8857],
          //   251: [10074, 10075, 10076, 10077, 10078, 10079, 10080, 10081, 10082, 10083, 10084, 10085, 10086, 10087, 10088,
          //     10089,
          //     10090, 10091, 10092, 10093, 10094, 10095, 10096, 10097, 10098, 10099, 10100, 10101, 10102, 10103, 10104,
          //     10105, 10106,
          //     10107, 10108, 10109, 10110, 10111, 10112, 10113, 10114, 10115, 10116, 10117, 10118, 10119, 10120, 10121,
          //     10122, 10123,
          //     10124, 10125, 10126, 10127, 10128, 10129, 10130, 10131, 10132, 10133, 10134, 10135, 10136, 10137, 10138,
          //     10139, 10140,
          //     10141, 10142, 10143, 10144, 10145, 10146, 10147, 10148, 10149, 10150, 10151, 10152, 10153, 10154, 10155,
          //     10156, 10157,
          //     10158, 10159, 10160, 10161, 10162, 10163, 10164, 10165, 10166, 10167, 10168, 10169, 10170, 10171, 10172,
          //     10173, 10174,
          //     10175, 10176, 10177, 10178, 10179, 10180, 10181, 10182, 10183, 10184, 10185, 10186, 10187, 10188
          //   ],
          //   363:[23575,23576,23577,23578,23579,23580,23581,23582,23583,23584,23585,23586,23587]
        },
        fineScoreType: {
          0: '不合格',
          1: '合格',
          2: '暂未批阅',
          3: '良好',
          4: '优秀',
        },
        // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
        userId: undefined,
        parameter: {
          arrindex: 0,
        },
        // userinfoID: JSON.parse(sessionStorage.getItem('userinfo')).id,
        userinfoID: undefined,
        FineComentArr: [],
        dragOptions: {
          sort: false, //定义生成的表单是否可以拖拽
        },
        logo,
        idGlobal,
        formConf,
        inputComponents,
        selectComponents,
        layoutComponents,
        labelWidth: 100,
        drawingList: [],
        drawingData: {},
        activeId: drawingDefalut[0].formId,
        drawerVisible: false,
        formData: {},
        dialogVisible: false,
        jsonDrawerVisible: false,
        generateConf: null,
        showFileName: false,
        activeData: "",
        saveDrawingListDebounce: debounce(340, saveDrawingList),
        saveIdGlobalDebounce: debounce(340, saveIdGlobal),
        explain: "", //自定义的作业说明
        id: null, //问卷答题情况返回的ID，提交批改需要
        formid: "", //每道题对应的表单id
        power: [], //每道题对应的能力标准
        textarea: "", //点评内容
        checkArr: {
          comment: "",
          score: "",
          checkList: [],
        }, //本地存储的批改数组，不包括批改人ID与问卷答题情况返回的ID
        showComment: true, //默认展开评论
        allNum: "", //已交学员数量
        // awaitNum: "", //待批数量
        stuList: [], //该作业下全部学员列表
        currentPage: 1, //当前页
        pagesize: 10, //每页数据量
        allSize: null, //全部数量
        filterStus: null,
        currentUser: {}, //当前的学员信息
        teaInfo: "", //批改者信息
        professorInfo: "", //专家信息
        commentInfo: "", //专家评论信息
        userStar: null, //评分（针对于每套问卷）
        imgurl: this.downloadURL,
        imgUrl: 'this.src="' + require("../../assets/images/home/avatar.png") + '"',
        visible: false, //专家评论框 显示或者隐藏
        fujianList: [], //附件题数组
        workAttr: {}, //作业属性
        currentCheckInfos: {},
        currentCheckInfo: {
          answerDuration: "", //答题时长
          createTime: "", //学员问卷提交时间
          totalPoints: 0, //总得分
          objectiveScore: "", //客观题得分
          objectiveAccuracy: "", //客观题正确率
          subjectiveScore: "", //主观题得分
          commentInfo: "", //专家评论
          userStar: "", //学员星星
          questionCheck: [], //当前问题的评分与能力评星
        }, //当前学员的批改信息
        firstScore: 0,
        videoTypes: [
          "mp4",
          "3gp",
          "m4v",
          "mkv",
        ],
        audioTypes: ["wav", "mp3", "aac", ],
        picTypes: ["bmp", "gif", "png", "jpg", "jpeg"],
        fileTypes: ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "txt", "wps", "zip", "rar", "avi", "dat", "flv",
          "vob", "mov", "rm", "rmvb", "wmv", "asf", "asx"
        ],
        hasstu: false, //该作业下是否有提交学员
        hassubjective: false, //该作业下是否有主观题
        value1: 0,
        isplay: false,
        audiotime: "",
        speakinfo: null,
        speakscore: null,
        mingtiscore: null,
        answertime: null,
        speakid: null, //普通话命题说话默认id
        id: null, //问卷答题情况返回的id,用于提交批改
        activeKind: null, //已交学员筛选 当前选项
        currentquestid: null,
        currentquestScore: null
      };
    },
    computed: {},
    watch: {
      // eslint-disable-next-line func-names
      "activeData.__config__.label": function (val, oldVal) {
        if (
          this.activeData.placeholder === undefined ||
          !this.activeData.__config__.tag ||
          oldActiveId !== this.activeId
        ) {
          return;
        }
        this.activeData.placeholder =
          this.activeData.placeholder.replace(oldVal, "") + val;
      },
      activeId: {
        handler(val) {
          oldActiveId = val;
        },
        immediate: true,
      },
      drawingList: {
        handler(val) {
          this.saveDrawingListDebounce(val);
          if (val.length === 0) this.idGlobal = 100;
        },
        deep: true,
      },
      idGlobal: {
        handler(val) {
          this.saveIdGlobalDebounce(val);
        },
        immediate: true,
      },
      speakinfo(newval) {
        this.speakinfo = newval
      }
    },
    mounted() {
      this.getAuthority();
      this.userinfoID = this.$store.state.user.userInfo.id
      this.userId = this.$store.state.user.userInfo.id
      localStorage.removeItem("checklist");
      localStorage.removeItem("drawingItems");
      this.getForm(); //获取已交学员列表第一位的问卷及答题情况
      this.getStuNum(); //获取该作业主题下的提交学员数量
      if (formConfInDB) {
        this.formConf = formConfInDB;
      }
      loadBeautifier((btf) => {
        beautifier = btf;
      });
    },
    activated() {
      this.getAuthority();
      this.userinfoID = this.$store.state.user.userInfo.id
      this.userId = this.$store.state.user.userInfo.id
      localStorage.removeItem("checklist");
      localStorage.removeItem("drawingItems");
      this.getForm(); //获取已交学员列表第一位的问卷及答题情况
      this.getStuNum(); //获取该作业主题下的提交学员数量
      this.getStuList();
      if (formConfInDB) {
        this.formConf = formConfInDB;
      }
      loadBeautifier((btf) => {
        beautifier = btf;
      });
    },
    methods: {
      FineComent(item, index) {
        // console.log('item:', item)
        this.parameter = item;
        this.parameter.arrindex = index;
      },
      //修改每页显示数量
      handleSizeChange(size) {
        this.pagesize = size;
        this.getStuList();
      },
      //切换当前页码
      handleCurrentChange(page) {
        this.fujianList = []
        this.currentPage = page;
        this.getStuList();
        this.getForm();
      },
      //切换用户
      changeUser(userid) {
        this.fujianList = [];
        this.userStar = null;
        this.$refs.check_right.scoreVal = null;
        this.$refs.check_right.checkArr.checkList = [];
        this.power = [];
        this.isplay = false
        this.currentUser = this.stuList.filter((item) => {
          return item.userId == userid;
        });
        this.currentUser = this.currentUser[0];
        this.getStuForm(userid);
      },
      //获取该作业下的学员数量
      getStuNum() {
        this.$Api.Form.getStuNum(this.workid)
          .then((value) => {
            this.allNum = value.data.total;
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      changeCompleteKind(item) {
        if (this.filterStus) {
          return;
        }
        this.fujianList = [];
        this.activeKind = item.id;
        this.currentPage = 1;
        this.getForm();
      },
      filterStu() {
        this.activeKind = null;
        this.currentPage = 1;
        this.fujianList = [];
        this.getForm();
      },
      //获取该作业下的学员列表
      getStuList() {
        let params = {
          questionnaireId: this.workid,
          page: this.currentPage,
          limit: this.pagesize,
          correctingStatus: this.activeKind,
        };
        params.keyword = this.filterStus ? this.filterStus : null
        return this.getAuthority()
          .then(res => {
            if (this.hideUsertype[this.$route.query.projectId] && this.hideUsertype[this.$route.query.projectId]
              .indexOf(this.userId) >= 0) {
              params.withGroupFilter = 1;
            }
            return this.$Api.Form.getStuList(params)
              .then((value) => {
                if (value.data.list.length > 0) {
                  this.hasstu = true;
                  this.stuList = value.data.list;
                  this.allSize = value.data.totalCount;
                  this.currentUser = value.data.list[0]; //当前显示的学员信息默认取数组第一项
                  // this.changeUser(this.currentUser.userId);
                } else {
                  this.stuList = [];
                  this.currentUser = {};
                  console.warn("该作业下没有学员");
                }
                return this.stuList;
              })
              .catch((reason) => {
                console.log(reason);
              });
          })
          .catch(err => {
            console.log('err:', err)
          })

      },
      //更新批改状态
      updateStatus() {
        let params = {
          questionnaireId: this.workid,
          page: this.currentPage,
          limit: this.pagesize,
          correctingStatus: this.activeKind,
        };
        if (this.hideUsertype[this.$route.query.projectId] && this.hideUsertype[this.$route.query.projectId].indexOf(
            this.userId) >= 0) {
          params.withGroupFilter = 1;
        }
        return this.$Api.Form.getStuList(params)
          .then((value) => {
            this.stuList = value.data.list;
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      //展开/收起评论
      toggle() {
        this.showComment = !this.showComment;
      },
      //获取学员列表第一的问卷及答题情况
      async getForm() {
        let resData = await this.getStuList();
        if (resData.length == 0) {
          console.log("暂无提交")
        } else {
          this.getStuForm(this.currentUser.userId);
        }
      },
      // 获取成绩
      getFineScore(userid, workid) {
        this.$Api.Form.getFineScore(
            userid,
            workid
          )
          .then((res) => {
            console.log('当前评论信息', res)
            this.currentCheckInfos = res.data;
          }).catch((err) => {
            console.log(err);
          });
      },
      // 获取成绩
      getFineComent(userid, workid) {
        this.$Api.Form.getFineComent(
            userid,
            workid
          )
          .then((res) => {
            console.log('批改评论----:', res.data)
            if(this.workAttr.score == 'false'){
              this.FineComentArr = res.data
            }else{
              this.FineComentArr = res.data[0]
            }
            
          }).catch((err) => {
            console.log(err);
          });
      },
      //获取指定学员的问卷信息及批改情况
      getStuForm(userid) {
        // 查询指定问卷的答题情况(包含批改情况)
        this.$Api.Form.checkForm(userid, this.workid)
          .then((res) => {

            this.workAttr = JSON.parse(res.data.qtitle);
            this.getFineComent(userid, this.workid)
            // if (this.workAttr.score == 'false') {
            this.getFineScore(userid, this.workid)

            // }
            if (this.themetype == 4) {
              this.speakinfo = JSON.parse(res.data.content)
              this.speakid = JSON.parse(res.data.content)[0].id
              this.$refs.audios.src = this.imgurl + this.speakinfo[0].result[0].id
              this.$Api.Form.getStuForm(this.workid)
                .then(res => {
                  //获取该学员的得分情况
                  let param = {
                    relId: res.data.mdQuestionnaireRelId,
                    userId: userid
                  }
                  this.$Api.Form.getreport(param)
                    .then(res => {
                      let minutes = Math.floor(res.data.consumeTime / 60);
                      let seconds = Math.floor(res.data.consumeTime % 60);
                      if (seconds < 10) {
                        seconds = "0" + seconds
                      }
                      if (minutes < 10) {
                        minutes = "0" + minutes
                      }
                      this.answertime = minutes + "分" + seconds + "秒"
                      this.speakscore = res.data
                    })
                    .catch(err => {
                      console.log("获取普通话得分失败", err)
                    })
                })
                .catch(err => {
                  console.log("获取relid失败！！！", err)
                })
            }
            // console.log("获取指定学员的问卷信息及批改信息--", res)
            //学员批改信息
            this.currentCheckInfo.answerDuration = res.data.answerDuration; //答题时长
            this.currentCheckInfo.createTime = res.data.createTime; //学员问卷提交时间
            this.currentCheckInfo.totalPoints = res.data.totalPoints; //总得分
            this.currentCheckInfo.objectiveScore = res.data.objectiveScore; //客观题得分
            this.currentCheckInfo.objectiveAccuracy = res.data.objectiveAccuracy; //客观题正确率
            this.currentCheckInfo.subjectiveScore = res.data.subjectiveScore; //主观题得分
            if (res.data.correctingContent) {
              // 如果有批改信息，则赋值给子组件
              this.$nextTick(() => {
                console.log('有批改信息哦～:', res.data);
                this.$refs.check_right.checkArr = JSON.parse(res.data.correctingContent);
                try {
                  if (JSON.parse(res.data.contentFine)) {
                    this.$refs.check_right.scoreArr = JSON.parse(res.data.contentFine)[0]
                  }
                } catch (err) {
                  console.log(err);
                  this.$refs.check_right.scoreArr = {
                    index: 0,
                    correctingFineScore: 0, //批改分数
                    correctingFineComment: '', //批改评语
                    correctingUserId: '', //批改者ID
                    questionnaireId: '', //问卷ID
                    userId: '', //答题者ID
                    createCorrectingTime: '', //创建批改时间
                    updateCorrectingTime: '', //修改批改时间
                  }
                }

              })

              this.currentCheckInfo.commentInfo = JSON.parse(
                res.data.correctingContent
              ).comment; //专家评论
              this.currentCheckInfo.userStar = JSON.parse(
                  res.data.correctingContent
                ).score ?
                JSON.parse(res.data.correctingContent).score :
                0; //学员星星
              this.currentCheckInfo.questionCheck = JSON.parse(
                res.data.correctingContent
              ).checkList; //问卷问题的评分与能力评星
              this.commentInfo = JSON.parse(res.data.correctingContent).comment;
              this.userStar = JSON.parse(res.data.correctingContent).score ?
                JSON.parse(res.data.correctingContent).score :
                0;
            } else {
              this.currentCheckInfo.commentInfo = "";
              this.currentCheckInfo.userStar = 0;
              this.currentCheckInfo.questionCheck = [];
            }
            this.professorInfo = res.data; //专家信息
            this.id = res.data.id; //问卷答题情况返回的id,用于提交批改
            //获取问卷题干
            let content = JSON.parse(res.data.qcontent);
            content.map((item) => {
              //取出作业说明
              if (item.__config__.tag == "tinymce") {
                this.explain = item.__config__.defaultValue;
              }
              //修改表单默认值类型，不然会报错
              if (item.__config__.tag == "el-checkbox-group") {
                item.__config__.defaultValue = [];
              } else {
                item.__config__.defaultValue = "";
              }
              //将表单改为只读或者禁用状态
              if (item.__config__.tag == "el-input") {
                item.readonly = true;
              } else {
                item.disabled = true;
              }
            });
            //将作业说明过滤出去只显示问卷内容
            content = content.filter((item) => {
              return item.__config__.tag != "tinymce";
            });
            for (var i = 0; i < content.length; i++) {
              if (
                content[i].__config__.tag == "el-input" ||
                content[i].__config__.tag == "el-upload"
              ) {
                this.hassubjective = true; //该作业下有主观题
                break;
              }
            }
            //获取学员答案
            let resultlist = JSON.parse(res.data.content);
            // console.log("学员答案", resultlist)
            for (let i = 0; i < content.length; i++) {
              for (let j = 0; j < resultlist.length; j++) {
                if (content[i].__vModel__ == resultlist[j].id) {
                  content[i].fjexplain = resultlist[j].explain
                  content[i].__config__.defaultValue = resultlist[j].result
                  resultlist.splice(j, 1)
                  break;
                }
              }
            }
            //操作附件题
            content.map((item) => {
              if (item.__config__.tag == "el-upload") {
                let obj = {
                  srclist: [],
                };
                obj.title = item.__config__.label;
                obj.id = item.__vModel__;
                obj.explain = item.fjexplain;
                obj.questionType = item.questionType || 0;
                obj.scoreVal = item.__config__.scoreValue;
                if (item.__config__.defaultValue.length > 0) {
                  obj.imglist = item.__config__.defaultValue.filter((item) => {
                    return this.picTypes.indexOf(item.type.toLowerCase()) != -1;
                  });
                  obj.videolist = item.__config__.defaultValue.filter((item) => {
                    return this.videoTypes.indexOf(item.type.toLowerCase()) != -1;
                  });
                  obj.audiolist = item.__config__.defaultValue.filter((item) => {
                    return this.audioTypes.indexOf(item.type.toLowerCase()) != -1;
                  });
                  obj.filelist = item.__config__.defaultValue.filter((item) => {
                    return this.videoTypes.indexOf(item.type.toLowerCase()) == -1 && this.picTypes.indexOf(
                        item.type.toLowerCase()) == -1 && this.audioTypes.indexOf(item.type.toLowerCase()) ==
                      -1;
                  });
                  item.__config__.defaultValue.map((item) => {
                    if (this.picTypes.indexOf(item.id.split("_")[2]) != -1) {
                      obj.srclist.push(this.imgurl + item.id);
                    }
                  });
                } else {
                  obj.imglist = [];
                  obj.videolist = [];
                  obj.audiolist = [];
                  obj.filelist = [];
                  obj.srclist = [];
                }
                //添加附件题
                this.fujianList.push(obj);
              }
            });
            //过滤客观题与附件题
            content = content.filter((item) => {
              return item.__config__.tag == "el-input";
            });
            return content;
          })
          .then((resData) => {
            let workContent = resData;
            this.$Api.Form.getWeightPower(this.workid) //获取该问卷的所有能力标准带权重的
              .then((res) => {
                //给问答题添加能力标准
                workContent.map((item) => {
                  if (res.data.hasOwnProperty(item.__vModel__) == true) {
                    item.__config__.powerVal = res.data[item.__vModel__];
                  }
                });
                //给附件题添加能力标准
                this.fujianList.map((item) => {
                  if (res.data.hasOwnProperty(item.id) == true) {
                    item.powerVal = res.data[item.id];
                  }
                });
                //设置默认选中的题目
                if (workContent.length > 0) {
                  this.activeData = workContent[0];
                  this.formid = workContent[0].__vModel__
                } else {
                  if (this.themetype == 4) {
                    this.formid = this.fujianList[this.fujianList.length - 1].id
                  } else {
                    this.formid = this.fujianList[0].id
                  }
                }
                //将处理好的主观题渲染到页面
                localStorage.setItem("drawingItems", JSON.stringify(workContent));
                this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
                this.defaultquest()
              });
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
      updateParameter(userid) {
        this.getFineComent(userid, this.workid)
        this.getFineScore(userid, this.workid)
        this.getStuList();
      },
      //更新当前学员的批改信息
      async updateCheckInfo() {
        // this.getStuList()
        this.updateStatus()
        let resData = await this.$Api.Form.checkForm(
          this.currentUser.userId,
          this.workid
        );
        this.professorInfo = resData.data
        this.currentCheckInfo.answerDuration = resData.data.answerDuration; //答题时长
        this.currentCheckInfo.createTime = resData.data.createTime; //学员问卷提交时间
        this.currentCheckInfo.totalPoints = resData.data.totalPoints; //总得分
        this.currentCheckInfo.objectiveScore = resData.data.objectiveScore; //客观题得分
        this.currentCheckInfo.objectiveAccuracy = resData.data.objectiveAccuracy; //客观题正确率
        this.currentCheckInfo.subjectiveScore = resData.data.subjectiveScore; //主观题得分
        if (resData.data.correctingContent) {
          this.commentInfo = JSON.parse(resData.data.correctingContent).comment;
          this.currentCheckInfo.commentInfo = JSON.parse(
            resData.data.correctingContent
          ).comment; //专家评论
          this.currentCheckInfo.userStar = JSON.parse(
              resData.data.correctingContent
            ).score ?
            JSON.parse(resData.data.correctingContent).score :
            0; //学员星星
          this.currentCheckInfo.questionCheck = JSON.parse(
            resData.data.correctingContent
          ).checkList; //问卷问题的评分与能力评星
        } else {
          this.currentCheckInfo.commentInfo = "";
          this.currentCheckInfo.userStar = 0;
          this.currentCheckInfo.questionCheck = [];
        }
        if (this.themetype == 4) {
          this.mingtiscore = this.currentCheckInfo.subjectiveScore
        }
        this.$refs.check_right.checkArr.checkList = this.currentCheckInfo.questionCheck;
      },
      //切换主观题目
      activeFormItem(currentItem) {
        this.currentquestid = currentItem.__vModel__;
        this.currentquestScore = currentItem.__config__.scoreValue;
        if (!currentItem) {} else {
          if (currentItem.__config__.powerVal) {
            this.activeData = currentItem;
            this.activeId = currentItem.__config__.formId;
            this.formid = currentItem.__vModel__;
            //切换对应题目的能力标准
            // this.power = currentItem.__config__.powerVal.split(",");
            this.power = currentItem.__config__.powerVal;
            let arr = [];
            this.power.map((item) => {
              let obj = {};
              obj.star = null;
              obj.power = item.powerName;
              obj.weight = item.weight;
              obj.score = 0;
              obj.powerId = item.powerId;
              arr.push(obj);
            });
            this.power = arr; //当前题目的能力标准
            let checkDetail = this.currentCheckInfo.questionCheck;
            if (checkDetail) {
              let itemdetail = checkDetail.filter((item) => {
                return item.formid == this.formid;
              });
              if (itemdetail.length == 0) {
                this.$refs.check_right.clearAll();
              } else {
                this.$refs.check_right.scoreVal = itemdetail[0].score;
                this.power = itemdetail[0].power;
              }
            } else {
              console.warn("当前题目没有被批改");
            }
          }
        }
      },
      //切换附件题
      choosequest(id) {
        this.currentquestid = id;
        this.activeId = id;
        //从data中获取当前选中的附件题
        let currentfujian = this.fujianList.filter((item) => {
          return item.id == id;
        });
        this.formid = currentfujian[0].id;
        this.currentquestScore = currentfujian[0].scoreVal
        //切换附件题对应的能力标准
        // this.power = currentfujian[0].powerVal.split(",");
        if (currentfujian[0].powerVal) {
          this.power = currentfujian[0].powerVal;
          let arr = [];
          this.power.map((item) => {
            let obj = {};
            obj.star = null;
            obj.power = item.powerName;
            obj.weight = item.weight;
            obj.score = 0;
            obj.powerId = item.powerId;
            arr.push(obj);
          });
          this.power = arr;
        } else {
          this.power = []
        }
        let checkDetail = this.currentCheckInfo.questionCheck;
        if (checkDetail.length > 0) {
          let itemdetail = checkDetail.filter((item) => {
            return item.formid == this.formid;
          });
          if (itemdetail.length == 0) {
            this.$refs.check_right.clearAll();
          } else {
            this.$refs.check_right.scoreVal = itemdetail[0].score;
            this.power = itemdetail[0].power;
          }

          this.getFineScore(this.currentUser.userId, this.workid)

        } else {
          // console.warn("当前题目没有被批改");
        }
      },
      // 根据能力⭐️计算分数
      computeScore(obj) {
        for (var i = 0; i < this.power.length; i++) {
          if (this.power[i].powerId == obj.powerId) {
            this.power[i].score = obj.powerScore
            break
          }
        }
      },

      //取消评论
      cancel() {
        this.visible = false;
        // this.userStar = 0
      },
      //专家点评
      subcomment() {
        if (this.userStar == 0 && !this.textarea.trim()) {
          this.$message({
            message: '请填写评分、点评任一项',
            type: 'warning'
          });
        } else {
          this.checkArr.score = this.userStar;
          this.checkArr.comment = this.textarea;
          let params = {
            correctingContent: JSON.stringify(this.checkArr),
            // correctingUserId: JSON.parse(sessionStorage.getItem("userinfo")).id,
            correctingUserId: this.$store.state.user.userInfo.id,
            id: this.id,
          };
          this.$Api.Form.subComment(params)
            .then((res) => {
              this.$message({
                type: "success",
                message: "提交成功",
              });
              this.visible = false;
              this.fujianList = [];
              this.textarea = "";
              this.updateCheckInfo();
            })
            .catch((err) => {
              console.warn(err);
            });
        }

      },
      //默认选中第一题对应的能力标准(优先问答题然后在附件题)
      defaultquest() {
        if (this.drawingList.length > 0) {
          this.activeFormItem(this.drawingList[0])
        } else {
          if (this.themetype == 4) {
            this.choosequest(this.fujianList[this.fujianList.findIndex(item => item.questionType == 4)].id)
          } else {
            this.choosequest(this.fujianList[0].id)
          }
        }
      },
      changeval(val) {
        this.$refs.audios.currentTime = val
      },
      //播放录音
      playaudio() {
        this.isplay = true
        this.$refs.audios.play()
      },
      //暂停录音
      pauseaudio() {
        this.isplay = false
        this.$refs.audios.pause()
      },
      audioready() {
        let minutes = Math.floor(this.$refs.audios.duration / 60);
        let seconds = Math.floor(this.$refs.audios.duration % 60);
        if (seconds < 10) {
          seconds = "0" + seconds
        }
        if (minutes < 10) {
          minutes = "0" + minutes
        }
        this.audiotime = minutes + ":" + seconds
      },
      //音频播放位置实时更改
      audiotimeupdate(val) {
        this.value1 = val.target.currentTime
      },
      audioebd() {
        this.isplay = false
      },
      // 获取项目下配置权限的 用户列表
      getAuthority() {
        let data = {
          type: 1,
        }
        return this.$Api.Home.getPermissionConf(data)
          .then(res => {
            this.hideUsertype = res.data;
            return Promise.resolve('获取项目下配置权限')
          })
          .catch(err => {
            console.log('配置权限err:', err)
          })
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/home";

  .checkWrap {
    // width: 1200px;
    // margin: 30px auto 0;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;

    .check_left {
      width: 319px;
      // padding: 0 28px;
      box-sizing: border-box;
      // height: 840px;
      flex-shrink: 0;
      background: #ffffff;
      border-radius: 5px;
      position: relative;
      border-left: 1px solid #f2f2f2;

      .student_title {
        line-height: 40px;
        box-sizing: border-box;
        padding: 0 15px 0 28px;
        border-bottom: 1px solid #f2f2f2;
        font-size: 13px;
        margin-top: 10px;

        .complete-kind {
          .kind-item {
            margin-right: 15px;
            cursor: pointer;
          }

          .active-item {
            color: #508EF9;
          }
        }

        ::v-deep .el-input__inner {
          border-radius: 25px;
        }
      }

      .student_wrap {
        box-sizing: border-box;
        padding: 15px 28px;
        // height: 700px;
        overflow-y: scroll;
        min-height: 700px;

        .student_item {
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          margin-bottom: 15px;
          padding: 8px 7px;
          cursor: pointer;

          .userInfo {
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;

            .num {
              min-width: 40px;
              // margin-right: 5px;
            }

            .avatar {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              margin: 0 10px 0 0;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &.currentStu {
            background-color: #f2f2f2;
            border-radius: 5px;
          }

          .item_status {
            font-size: 14px;
            color: #666666;
          }
        }
      }

      .student_wrap::-webkit-scrollbar {
        width: 0 !important;
      }

      .pagination {
        width: 100%;

        display: flex;
        justify-content: space-around;

        // position: absolute;
        // left: 0;
        // bottom: 20px;
        ::v-deep .el-pagination__jump {
          display: block;
          text-align: center;
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }

    .check_right {
      // width: 900px;
      flex: 1;

      .userInfo {
        padding: 30px 46px;

        .user {
          box-sizing: border-box;

          .avatar {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background: #edf1f8;
            margin-right: 29px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .info {
            .info_data {
              div {
                font-size: 13px;
                margin-right: 10px;
                font-weight: bold;
              }

              span {
                color: #ff9900;
                font-size: 14px;
                font-weight: bold;
              }
            }

            .time {
              margin-top: 3px;
              font-size: 12px;
              color: #666666;
            }
          }
        }

        .score {
          height: 103px;
          background: rgba(204, 204, 204, .1);
          margin: 18px 0 23px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .score_item {
            display: flex;
            align-items: center;
            flex-direction: column;

            .score_num {
              color: #5572E8;
              font-size: 25px;
              font-weight: bold;

              span {
                font-size: 14px;
              }
            }

            .score_name {
              color: #333333;
              font-size: 12px;
            }
          }

          .score_item:nth-child(2) .score_num {
            color: #36C2CF;
          }

          .score_item:nth-child(3) .score_num {
            color: #F6B926;
          }

          .score_item:nth-child(4) .score_num {
            color: #EF5757;
          }

          .score_item:nth-child(5) .score_num {
            color: #B05BCF;
          }
        }

        // 1111



        .block {

          // border:1px solid red;
          // background: red;
          .user-carousel {
            padding: 10px 60px;

            .item-left {
              img {
                width: 33px;
                height: 33px;
                border-radius: 50%;
                background: #edf1f8;
                overflow: hidden;
              }

              .name {
                font-size: 14px;
                margin-left: 10px;
              }

              .time {
                font-size: 14px;
                margin-left: 10px;
              }

              .bianji {
                margin-left: 10px;
                color: #508EF9;
                font-size: 14px;
                cursor: pointer;
              }

            }

            .item-right {

              margin: 10px 10px;
              font-size: 14px;
              word-wrap: break-word;
              // white-space: normal;
              // word-break: break-all;
              height: 70px;
              max-height: 70px;
              overflow: auto;
              border-radius: 10px;
            }
          }

        }

        .fun {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin: 0 40px;

          span {
            display: flex;
            align-items: center;
            margin-left: 20px;

            img {
              margin-right: 10px;
            }
          }

          .comment_wrap {
            width: 700px;
            height: 450px;
            background: #ecf0f7;
          }
        }

        .toggle {
          margin: 10px 40px 0;
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 14px;
        }

        .teaComment {
          margin: 10px 40px;

          // background: #ff9900;
          .teaInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .info {
              display: flex;
              align-items: center;

              .tea_avatar {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background: #edf1f8;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .tea_name {
                font-size: 14px;
                font-weight: 500;
                color: #666666;
                margin: 0 22px;
              }

              .tea_time {
                font-size: 14px;
                color: #666666;
              }
            }

            .menu {
              font-size: 14px;
              color: #666666;

              .removeComment {
                margin-left: 36px;
              }
            }
          }

          .content {
            margin-left: 60px;
            padding-bottom: 22px;
          }
        }
      }

      .container {
        display: flex;
        height: auto;
        min-height: 500px;
        // width: 900px;
        // justify-content: space-around;
        margin: 0;
        padding: 0 46px;
        box-sizing: border-box;

        .center-board {
          flex: 1;
          margin: 0 0 0 0;
          border: 0;
          border-radius: 5px;


          .center-scrollbar {
            border: 0;

            .fujian {
              padding: 0 7.5px;
              margin-top: 15px;
              background: #f6f7ff;

              .fjexplin {
                margin-bottom: 10px;
                word-break: break-all;
                white-space: pre-line;
              }

              .file {
                padding-left: 10px;
                margin: 10px 0px;
                height: 50px;
                display: flex;
                align-items: center;
                background: #EBEEF5;

              }
            }

            // .fujian:hover {
            //   background: #f6f7ff;
            // }
          }

          .speakcontent {
            margin: 30px;

            .record {
              margin-top: 20px;
              width: 300px;
              height: 50px;
              background: #F6FAFF;
              border-radius: 10px;

              .block {
                width: 160px;
              }

              img {
                width: 30px;
              }
            }
          }

          .fujian {
            padding: 0 7.5px;
            margin-top: 15px;
            // background: #f6f7ff;

            .fjexplin {
              margin-bottom: 10px;
              word-break: break-all;
              white-space: pre-line;
            }

            .file {
              padding-left: 10px;
              margin: 10px 0px;
              height: 50px;
              display: flex;
              align-items: center;
              background: #EBEEF5;

            }
          }

          .fujian:hover {
            background: #f2f2f2;
          }

          .actived {
            background: #f2f2f2;

            // ::v-deep .el-form-item {
            //   background: none;
            // }

            // ::v-deep .el-form-item:hover {
            //   background: #f6f7ff;
            // }
          }

          ::v-deep .el-form-item {
            background: none;
          }

          ::v-deep .el-form-item:hover {
            background: #f2f2f2;
          }
        }
      }

      .empty_sub {
        width: 100%;
        // height: 80%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }

    .empty {
      width: 100%;
      min-height: 400px;
      justify-content: space-around;

      p {
        margin-top: -250px;
        font-size: 16px;
        color: #999;
      }
    }
  }
</style>